// toggle between scheduled and flexible
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'scheduledRadioYes' ]

  connect(){
    this.toggleScheduledFlexible(this.scheduledRadioYesTarget.checked);
  }

  onSelectActivityType(event) {
    this.toggleScheduledFlexible(event.target.value == 'true');
  }

  toggleScheduledFlexible(isScheduled){
    if(isScheduled == true){
      $('.flexible').hide();
      $('.scheduled').show();
      this.removeAnnualOptionFromRepeatsSelect();
      // $('.weeks_on').text('week on');
    }
    else{
      $('.flexible').show();
      $('.scheduled').hide();
      // $('.weeks_on').text('week');
      this.addAnnualOptionFromRepeatsSelect();
    }
  }

  removeAnnualOptionFromRepeatsSelect(){
    const optionExists = ($('#job_content_repeats option[value=never]').length > 0);
    if(optionExists)
    {
      $('#job_content_repeats option[value=never]').remove();
    }
  }

  addAnnualOptionFromRepeatsSelect(){
    const optionExists = ($('#job_content_repeats option[value=never]').length > 0);
    if(!optionExists)
    {
      $('#job_content_repeats').append("<option value=never>Never</option>");
    }
  }
}
