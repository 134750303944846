import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { index: Number }

  connect() {
    const slideoutMenu = this.element;
    const dom_id = this.element.id;
    const savedScrollPosition = localStorage.getItem(dom_id);

    if (slideoutMenu) {
      if (savedScrollPosition === null) {
        this.scrollToActiveTab();
      } else {
        this.scrollToSavedPosition(slideoutMenu, savedScrollPosition, dom_id);
      }
    }

    this.storeScrollPositionOnClick(slideoutMenu, dom_id);
  }

  scrollToActiveTab() {
    document.querySelectorAll('.nav-tab-title').forEach((navTabTitle, index) => {
      const skipIndex = this.hasIndexValue ? this.indexValue : 0;

      if (navTabTitle.classList.contains('active') && index !== skipIndex) {
        let currentElement = navTabTitle.parentElement;
        if (currentElement) {
          currentElement.scrollIntoView({ behavior: 'auto', block: 'center' });
        } else {
          navTabTitle.scrollIntoView({ behavior: 'auto', block: 'center' });
        }
      }
    });
  }

  scrollToSavedPosition(slideoutMenu, savedScrollPosition, dom_id) {
    slideoutMenu.scrollTop = savedScrollPosition;
    localStorage.removeItem(dom_id);
  }

  storeScrollPositionOnClick(slideoutMenu, dom_id) {
    document.querySelectorAll('.nav-tab-title').forEach((navTabTitle) => {
      navTabTitle.addEventListener('click', () => {
        localStorage.setItem(dom_id, slideoutMenu.scrollTop);
      });
    });
  }
}
