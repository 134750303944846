// disable or hide action buttons, inputs etc

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this._disable(this.element)

    addEventListener('turbo:frame-load', ({ target }) => {
      this._disable(target)
    })
  }

  _disable(context) {
    context.querySelectorAll('button, input:not([type=hidden]), optgroup, select, textarea, .btn-add, .btn-add-file, .btn-attach, .btn.delete, .btn-cancel, .clickable_td, select.tomselected').forEach((el) => {
      if(el.getAttribute('data-prevent-disable') != 'true') {
        el.setAttribute('disabled', 'disabled')
        el.setAttribute('aria-disabled', 'true')
        el.removeAttribute('data-action') // e.g. .clickable_td have stimulus action assigned
        if(el.nodeName === 'A') {
          el.setAttribute('href', '#')
        }

        // required to disable tomselect
        if(el.classList.contains('tomselected')) {
          el.tomselect.disable()
        }
      }
    })

    context.querySelectorAll('.button, a.destroy, a.edit, .mark-as-button, .edit-row, .remove-row, .remove-attachment, .recalculate').forEach((el) => {
      if(el.getAttribute('data-prevent-disable') != 'true') {
        el.classList.add('d-none')
      }
    })
  }
}
