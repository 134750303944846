// used in app/views/edocs

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'form' ]

  formTargetConnected(target) {
    target.reset()
  }
}
