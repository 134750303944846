// used in non-medical job plans, job content form
// repeats_every_n_weeks (select), repeats_weekly_each_days_of_the_week (day boxes),
// repeats_rota (week circles) management
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'everyWeeks' ]

  connect(){
    // get value of repeats_every_n_weeks select 'Every' (1..52) 'week(s)' and use to show relevant week circles
    const everyWeeks = this.everyWeeksTarget.value;
    this.pluralizeWeek(everyWeeks);
    if (everyWeeks > 1){
      this.hideAllWeekCircles();
      $('#repeats_rota').show();
      this.showWeekCircles(everyWeeks);
    }

    // get value of hidden repeats_rota select [(1..52)] and use to select week circles
    var repeats_rota = $("#job_content_repeats_rota").val();
    this.selectWeekCircles(repeats_rota);

    // get value of 'repeats_weekly_each_days_of_the_week' select (Mon-Sun) and use to highlight selected days
    var days_of_the_week= $("#job_content_repeats_weekly_each_days_of_the_week").val();
    this.selectDaysOfTheWeek(days_of_the_week);

  }

  // when select an option from the on 'Every' (1..52) 'week(s)' select
  onSelectEveryWeeksOption(event){
    const everyWeeks = event.target.value;
    this.pluralizeWeek(event.target.value);
    if (everyWeeks > 1){
      $('#repeats_rota').show();
    } else {
      $('#repeats_rota').hide();
    }
    this.hideAllWeekCircles();
    this.showWeekCircles(everyWeeks);
  }

  // when click on week circle
  onClickWeekCircle(event){
    // add selected class
    $(event.target).toggleClass('selected');

    // and update hidden array field
    var selected_weeks=[];
    $.each($(event.target).closest('tr').children("td.selected"), function( index, value ) {
      selected_weeks.push(value.getAttribute('data-value'))
    });
    $("#job_content_repeats_rota").val(selected_weeks);
  }

  // when click on day of the week
  onClickDay(event){
    // add selected class
    $(event.target).toggleClass('selected');

    // and update hidden array field
    var selected_days=[];
    $.each($(event.target).closest('tr').children("td.selected"), function( index, value ) {
      selected_days.push(value.getAttribute('data-value'))
    });
    $("#job_content_repeats_weekly_each_days_of_the_week").val(selected_days);
  }

  //

  hideAllWeekCircles() {
    $('.rota-week-selector .clickable_td').hide();
  }

  showWeekCircles(everyWeeks) {
    // show clickable_td for week options if repeating less than once a week
    if (everyWeeks > 1){
      this.hideAllWeekCircles();
      $('#repeats_rota').show();
      everyWeeks = parseInt(everyWeeks, 10);
      ++everyWeeks;
      var range = _.range(1,everyWeeks, 1);
      $.each(range, function( everyWeeks, value ) {
        $("td[data-value='" + value + "']").show();
      });
    }
  }

  selectWeekCircles(repeats_rota) {
    if (repeats_rota) {
      $.each(repeats_rota, function( index, value ) {
        $("td[data-value='" + value +"']").addClass('selected');
      });
    }
  }

  selectDaysOfTheWeek(days_of_the_week) {
    if (days_of_the_week) {
      $.each(days_of_the_week, function( index, value ) {
        $("td[data-value='" + value +"']").addClass('selected');
      });
    }
  }

  pluralizeWeek(count){
    if (count > 1){
      $('.weeks_on').text('weeks');
    } else {
      $('.weeks_on').text('week');
    }
  }

}
