import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { tenantHasNestedTeams: Boolean, tenantHasNestedSites: Boolean, tenantHasNestedLocations: Boolean,
                    optionsOtherDisabled: Boolean,
                    allSitesData: Array, allLocationsData: Array, allTeamsData: Array,
                    allSitesLabel: String, nestedSitesLabel: String, otherSitesLabel: String,
                    allLocationsLabel: String, nestedLocationsLabel: String, otherLocationsLabel: String,
                    allTeamsLabel: String, nestedTeamsLabel: String, otherTeamsLabel: String }
  static targets = ['team', 'site', 'directorate', 'location']

  directorateChange() {
    if(!this.tenantHasNestedLocationsValue || !this.hasLocationTarget) {
      return
    }

    const oldLocationId = this.locationTarget.querySelector('option:checked')?.value
    const directorateId = this.directorateTarget.querySelector('option:checked')?.value

    const locationTs = this.locationTarget.tomselect

    locationTs.clear()
    locationTs.clearOptions()

    if(!directorateId) {
      locationTs.addOptionGroup(this.allLocationsLabelValue, { label: this.allLocationsLabelValue })
      const all_opts = this.allLocationsDataValue.map(item => ({ value: item[1], text: item[0], optgroup: this.allLocationsLabelValue }))
      locationTs.addOptions(all_opts)
      return
    }

    const url = `/directorates/${directorateId}/location_org_l4s.json`

    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(r => r.json())
    .then(data => {
      const nested_data = data.map(item => ({ value: item[1], text: item[0], optgroup: this.nestedLocationsLabelValue }))

      let other_data = this.allLocationsDataValue.filter(item => !nested_data.some(s => s.value === item.value))
      const otherDataLabel = nested_data.length > 0 ? this.otherLocationsLabelValue : this.allLocationsLabelValue
      other_data = other_data.map(item => ({ value: item[1], text: item[0], optgroup: otherDataLabel, disabled: this.optionsOtherDisabledValue }))

      const opts_data = nested_data.concat(other_data)

      if(nested_data.length > 0) {
        locationTs.addOptionGroup(this.nestedLocationsLabelValue, { label: this.nestedLocationsLabelValue })
      }

      if(other_data.length > 0) {
        locationTs.addOptionGroup(otherDataLabel, { label: otherDataLabel })
      }

      locationTs.addOptions(opts_data)

      if(nested_data.find(item => item.value === oldLocationId) || nested_data.length == 0) {
        locationTs.setValue(oldLocationId)
      }
    })
  }

  locationChange() {
    if(!this.tenantHasNestedTeamsValue || !this.hasTeamTarget) {
      return
    }

    const oldTeamId = this.teamTarget.querySelector('option:checked')?.value
    const locationId = this.locationTarget.querySelector('option:checked')?.value

    const teamTs = this.teamTarget.tomselect

    teamTs.clear()
    teamTs.clearOptions()

    if(!locationId) {
      teamTs.addOptionGroup(this.allTeamsLabelValue, { label: this.allTeamsLabelValue })
      const all_opts = this.allTeamsDataValue.map(item => ({ value: item[1], text: item[0], optgroup: this.allTeamsLabelValue }))
      teamTs.addOptions(all_opts)
      return
    }

    const url = `/location_org_l4s/${locationId}/team_org_l5s.json`

    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(r => r.json())
    .then(data => {
      const nested_data = data.map(item => ({ value: item[1], text: item[0], optgroup: this.nestedTeamsLabelValue }))

      let other_data = this.allTeamsDataValue.filter(item => !nested_data.some(s => s.value === item.value))
      const otherDataLabel = nested_data.length > 0 ? this.otherTeamsLabelValue : this.allTeamsLabelValue
      other_data = other_data.map(item => ({ value: item[1], text: item[0], optgroup: otherDataLabel, disabled: this.optionsOtherDisabledValue }))

      const opts_data = nested_data.concat(other_data)

      if(nested_data.length > 0) {
        teamTs.addOptionGroup(this.nestedTeamsLabelValue, { label: this.nestedTeamsLabelValue })
      }

      if(other_data.length > 0) {
        teamTs.addOptionGroup(otherDataLabel, { label: otherDataLabel })
      }

      teamTs.addOptions(opts_data)

      if(oldTeamId && nested_data.find(item => item.value === oldTeamId) || nested_data.length == 0) {
        teamTs.setValue(oldTeamId)
      }
    })
  }

  teamChange() {
    if(!this.tenantHasNestedSitesValue || !this.hasSiteTarget) {
      return
    }

    const oldSiteId = this.siteTarget.querySelector('option:checked')?.value
    const teamId = this.teamTarget.querySelector('option:checked')?.value

    const siteTs = this.siteTarget.tomselect

    siteTs.clear()
    siteTs.clearOptions()

    if(!teamId) {
      siteTs.addOptionGroup(this.allSitesLabelValue, { label: this.allSitesLabelValue })
      const all_opts = this.allSitesDataValue.map(item => ({ value: item[1], text: item[0], optgroup: this.allSitesLabelValue }))
      siteTs.addOptions(all_opts)
      return
    }

    const url = `/team_org_l5s/${teamId}/site_names.json`

    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(r => r.json())
    .then(data => {
      const nested_data = data.map(item => ({ value: item[1], text: item[0], optgroup: this.nestedSitesLabelValue }))

      let other_data = this.allSitesDataValue.filter(item => !nested_data.some(s => s.value === item.value))
      const otherDataLabel = nested_data.length > 0 ? this.otherSitesLabelValue : this.allSitesLabelValue
      other_data = other_data.map(item => ({ value: item[1], text: item[0], optgroup: otherDataLabel, disabled: this.optionsOtherDisabledValue }))

      const opts_data = nested_data.concat(other_data)

      if(nested_data.length > 0) {
        siteTs.addOptionGroup(this.nestedSitesLabelValue, { label: this.nestedSitesLabelValue })
      }

      if(other_data.length > 0) {
        siteTs.addOptionGroup(otherDataLabel, { label: otherDataLabel })
      }

      siteTs.addOptions(opts_data)

      if(oldSiteId && nested_data.find(item => item.value === oldSiteId) || nested_data.length == 0) {
        siteTs.setValue(oldSiteId)
      }
    })
  }
}
