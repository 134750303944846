import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["smallScreen", "largeScreen"]

  connect() {
    this.updateVisibility()
    window.addEventListener('resize', () => this.updateVisibility())
  }

  disconnect() {
    window.removeEventListener('resize', () => this.updateVisibility())
  }

  updateVisibility() {
    const isLargeScreen = window.innerWidth >= 1200 // xl breakpoint

    this.smallScreenTargets.forEach(el => {
      el.style.display = isLargeScreen ? 'none' : 'block'
    })

    this.largeScreenTargets.forEach(el => {
      el.style.display = isLargeScreen ? 'block' : 'none'
    })
  }
}
